<template>
  <div class="main">
    <div class="solution">
      <div class="pageBanner">
        <div class="banner">
          <div class="describe">
            <div class="tit">解决方案<span>/SOLUTION</span></div>
            <div class="txt">
              为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的<br />大数据企业。
            </div>
          </div>
        </div>
      </div>
      <div class="serveCont">
        <div class="tabs">
          <ul>
            <li
              v-for="(item, k) in tabsList"
              :key="k"
              :class="activeClass == item.id ? 'active' : ''"
              @click="tabsCli(item)"
            >
              <router-link :to="item.url">{{ item.tit }}</router-link>
            </li>
          </ul>
        </div>
        <div class="tabsDtail">
          <div class="tit">
            <div style="font-size: 18px">基础数据处理服务中心</div>
          </div>
          <div class="basiCont">
            <div class="basiTit">
              为政府机关、公共图书馆、出版社、医院、科研院所、高等院校等企事业单位，提供基础数据的加工处理、清洗、打通数据壁垒，为“数据多跑路，人民少跑路”打下坚实基础。
            </div>
            <div class="basicImg">
              <img src="../assets/basic1.png" alt="" />
            </div>
            <!-- 应用部分 -->
            <div class="appTxt">
              <span class="line"></span>
              <div class="yy">应用</div>
              <div class="appTit textIndent">
                新讯科技拥有先进的专业技术、一流的专业设备、高效的管理经验以及专业化管理队伍。在处理报刊、档案、图书、文献等大型数字化工程与全文检索制作等方面具备独特优势。
              </div>
              <div class="appPage">
                <div
                  class="appPagebox"
                  v-for="(item, k) in appDataList"
                  :key="k"
                >
                  <div class="tw">
                    <span><img :src="item.imgs" alt="" /></span
                    ><span>{{ item.tit }}</span>
                  </div>
                  <div class="w">{{ item.cont }}</div>
                </div>
              </div>
            </div>
            <!-- 这是新增案例部分 -->
            <div class="appTxt">
              <span class="line"></span>
              <div class="yy">案例</div>
              <div class="appTit">
                <span>一、《国家图书馆公有领域图书数字化加工服务项目》</span>
                <br />
                <span
                  class="textIndent"
                  style="display: inline-block; color: #666"
                >
                  2019年，公司在《国家图书馆公有领域图书数字化加工服务项目》招投标中脱颖而出，获得该项目的加工实施权。公司工艺流程管理部门迅速熟悉《国家图书馆公有领域图书数字化加工规范》，并以《国家图书馆中文图书数字化加工规范》为总的纲要，解读并整理了可供实施的加工流程、工序规范等相关文件。项目部门及生产部门协同，在驻场扫描、异地处理的加工方式下，以9个月工期为限，高质量的完成了百万页图书数字化工程。</span
                >
              </div>
              <div class="appTit">
                <span>二、《脱贫攻坚数字化成果项目》</span> <br />
                <span
                  class="textIndent"
                  style="display: inline-block; color: #666"
                  >为响应“巩固脱贫攻坚成果，确保精准扶贫档案完整、真实、安全、规范”的号召，公司受湖南省桃源县政府委托，于2020年4月对县内漳江镇、浔阳镇、陬市镇、漆河镇、木塘垸镇等若干个乡镇的扶贫档案进行了专业的整理工作，项目类别涉及了产业扶贫、危房改造、金融扶贫、社会保障、教育扶贫、健康扶贫、饮水安全、就业扶贫等若干类别。项目从接到任务、政策学习、样品制作、规范制定、流程制定、生产实施，总时长不到两个月，相关成果得到了对应地区相关领导的认可和表扬，也为扶贫档案进一步数字化工作打下了良好的基础。</span
                >
              </div>
              <div class="appTit">
                <span>三、《高等学校数字档案室（馆）建设项目》</span><br />
                <span
                  class="textIndent"
                  style="display: inline-block; color: #666"
                >
                  公司在高校领域有着十多年的客户积累，成功合作案例超过千家。2020年，在中共浙江省委办公厅、浙江省人民政府《关于开展电子文件和数字档案登记备份工作的通知》、《浙江省电子文件和数字档案登记备份工作实施方案》、《浙江省档案登记备份工作规范》、《浙江省档案局关于开展全省数字档案室建设测评的通知》、《
                  浙江省教育厅办公室 浙江省档案局办公室
                  关于推进高等学校数字档案室（馆）建设工作的通知》等要求下，新讯科技和浙江工商大学就该校校史档案数字化加工项目达成合作意向，新讯科技在遵从《中华人民共和国档案法》、《浙江省省直单位纸质档案数字化实施细则》、《浙江省档案馆纸质档案数字化成果接收暂行办法》、《浙江省省直单位数字档案资源接收进馆与登记备份数据包技术要求》等规范内容前提下，有针对性的对该校相关档案内容进行整理加工，在疫情政策的大前提下，驻场完成了该项工作，并顺利在省馆挂网备份。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeClass: 1,
      tabActive: 1,
      appDataList: [
        {
          id: 0,
          imgs: require("../assets/app1.png"),
          tit: "档案数字化",
          cont: "以国家档案局发布的《纸质档案数字化技术规范》图像处理检验标准为基础，可根据客户具体需求针对性调整。采用智能OCR识别、数字摄影、数据库、流媒体、存储等技术，以系统专业的团队，提供全国驻场/离场文档整理及数字化加工服务。",
        },
        {
          id: 1,
          imgs: require("../assets/app3.png"),
          tit: "报纸数字化",
          cont: "全流程系统70余道数据处理工序，对纸质或各类电子格式的报纸内容提供数据化服务，最终以检索系统的形式进行成品交付，其中图像为400dpi以上高清无倾斜无污渍内容，文字错误率控制在万分之一以下，内容标引项最多可达40余类，100%状态下双层PDF反显低于1毫米。",
        },
        {
          id: 2,
          imgs: require("../assets/app2.png"),
          tit: "表单数字化",
          cont: "利用新讯科技的智能表格识别技术，从各类机打或手写表单的单元格中采集数据，并填入到相应数据库字段中。广泛应用于各类申请表、登记表、申报表、调查问卷、票据、快运单等数据采集业务。",
        },
        {
          id: 3,
          imgs: require("../assets/app4.png"),
          tit: "图书数字化",
          cont: "提供标准差异化的图书扫描加工、精度细分化的图书文本制作、格式多样化的电子书转码加工、以及图书文字摘录等服务。内容可设计纸本简体新书、拥有电子数据的简体新书、港澳台及国外繁体新书、繁体日书、民国图书、杂志、技术报告、学位论文、影印古籍、珍本古籍、特藏图书、年鉴、地方志等。",
        },
      ],
      tabsList: [
        {
          id: 1,
          url: "/solution/basic_data?tab=1",
          tit: "基础数据处理服务中心",
        },
        {
          id: 2,
          url: "/solution/data_apply?tab=2",
          tit: "数据应用研发服务中心",
        },
        {
          id: 3,
          url: "/solution/business_scenes?tab=3",
          tit: "业务场景综合应用中心",
        },
        {
          id: 4,
          url: "/solution/cloud?tab=4",
          tit: "智慧云服务中心\xa0 \xa0 \xa0 \xa0 \xa0\xa0",
        },
      ],
    };
  },
  methods: {
    tabsCli(id) {
      this.activeClass = id.id;
    },
  },
};
</script>
<style lang="scss">
@import "../scss/solution.scss";
</style>